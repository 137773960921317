
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.page-statistics {
  display: flex;
  flex-direction: column;

  &__iframe {
    flex-grow: 1;
  }
}
